<template>
   <div class="chapterDetail_box">
      <div class="chapterDetail_body">
        <div class="chapterDetail_title_card">{{ list_item.subjectTitle }}</div>
        <div class="chapterDetail_body_box">
            <div
            class="chapterDetail_card"
            v-for="(res, itemIndex) in list_item.answerList" 
            :style="res.isRight == '1' ? 'background:#E5EFFF;':'' 
            || res.indexes == setMyAnwser(list_item) ? 'background:#FFF3F1;':'' ">
                <div class="answer_item">
                <div class="answer_continer margin_v1" 
                :style="res.isRight == '1' ? 'background:#3178EE;border:none':'' 
                || res.indexes == setMyAnwser(list_item) ? 'background:#E84423;border:none':'' ">
                    <div class="answer_name" 
                    :style="res.isRight == '1' ? 'color:#ffffff;':'' 
                    || res.indexes == setMyAnwser(list_item) ? 'color:#ffffff;':'' ">
                    {{ res.indexes }}</div>
                </div>
                <div class="answer_name margin_v row_width">{{ res.answerName }}</div>
                </div>
            </div>
            <div class="chapterDetail_answer_box">
                <div class="chapterDetail_answer_box_item right_line flex_with">
                    <div class="my_answer_title">您的作答</div>
                    <div class="my_answer_title my_answer">{{setMyAnwser(list_item)}}</div>

                </div>
                <div class="chapterDetail_answer_box_item  right_line flex_with_center">
                    <div class="yes_answer_title">正确答案</div>
                    <div class="yes_answer_title my_answer">{{setRightAnwser(list_item)}}</div>
                </div>
                <div class="chapterDetail_answer_box_item flex_with">
                    <div class="all_answer_title">全站正确率</div>
                    <div class="all_answer_title my_answer" style='color: #333333;'>{{list_item.totalAccuracy}}%</div>
                </div>

            </div>
            <div style="width:100%;height:10px;"></div>
            <div class="chapterDetail_answerNumber_box">
                <div class="allAnswerNumber_box">
                    本题总答题：{{list_item.countNumber}}次
                </div>
                <div class="allAnswerNumber_box">
                    <div class="flex_with">答对：{{list_item.rightNumber}}次</div>
                    <div class="flex_with_center">答错：{{list_item.errorNumber}}次</div>
                    <div class="flex_with flex_right">正确率：{{((list_item.rightNumber/list_item.countNumber)*100).toFixed(2)}}%</div>
                </div>
                

            </div>
            <div class="text_analysis_title">
                <span>文字解析</span>
                <div class="text_analysis_body" v-html="list_item.analysisText">
                    <!-- {{ list_item.analysisText }} -->
                </div>

            </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import {
  subject_analysis
} from "@/api/questionbook.js";
export default {
    props:['subjectId'],
    data() {
        return {
            list_item:{}
        }
    },
    name: 'analysis_checkbox',
    methods: {
        setMyAnwser(list){
            if(list.answerList && list.answerList.length > 0){
                if(list.doneAnswerIds1 != null && list.doneAnswerIds.indexOf(",") != -1){
                    let items = list.doneAnswerIds.split(",");
                    let val = [];
                    items.map(res=>{
                        
                        list.answerList.map(item => { 
                            if(res === item.answerId){
                                val.push(item);

                            }
                        });
                    })
                    if(val.length ==0){
                        return "/";
                    }else{
                        let str = '';
                        val.map(item=>{
                            str += item.indexes
                        })
                        return str;
                    }
                    
                }else{
                    let val = list.answerList.filter(item => { return list.doneAnswerIds === item.answerId });
                    if(val.length ==0){
                        return "/";
                    }else{
                        return val[0].indexes;
                    }
                }
                
                
            }
        },
        setRightAnwser(list){
            if(list.answerList && list.answerList.length > 0){
                let val = list.answerList.filter((item)=>{ return item.isRight == "1" });
                let str = '';
                val.map(item=>{
                    str += item.indexes
                })
                return str;
            }

        },
        init(){
            subject_analysis(this.subjectId).then((res)=>{
                this.list_item = res.data.data;
            })
            
        },
    },
    created(){
        
        

    },
    mounted () {
        this.init();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.text_analysis_title{
    margin: 17px 14px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    .text_analysis_body{
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
    }
}

.chapter_shoucang_box{
  width:18px;
  height: 16px;
  margin-right: 15px;
}
.right_line{
    border-right: 1px solid #DDDDDD;
}

.bj_sc_box{
    display: flex;
    justify-content: space-between;
}
.margin_v{
    margin-left: 12px;
}
.margin_v1{
    margin-left: 10px;
}
.flex_with{
    flex:0.3
}
.flex_with_center{
    flex: 0.4;
    justify-content: center;
    display: flex;
}
.flex_right{
    display: flex;
    justify-content: right;
    text-align: right;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.chapterDetail_answerNumber_box{
    display: flex;
    flex-direction: column;
    margin: 0 14px 0px 14px;
    background: white;
    height: 67px;
    justify-content: center;
    
    .allAnswerNumber_box{
        height: 23px;
        margin: 0 21px 0px 21px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

    }
}
.chapterDetail_answer_box{
    display: flex;
    flex-direction: row;
    margin: 0 14px 0px 14px;
    background: white;
    height: 67px;
    justify-content: center;
    align-items: center;
    .chapterDetail_answer_box_item{
        height: 43px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        .my_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E84423;
        }
        .my_answer{
            margin-top: 3px;

        }
        .yes_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #387CEF;
        }
        .all_answer_title{
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
    }
    
}
.chapterDetail_box{
    display: flex;
    width: 100%;
    height: calc(100vh - 46px);
    background: #ffffff;
    flex-direction: column;
    position: relative;
    .box_with{
        display: flex;
        width: 100%;
        height: 45px;
        background: #ffffff;
        .title_box{
            margin: 0px 14px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;
        }

    }
    .chapterDetail_body_box{
        width:100%;
        display: flex;
        flex-direction: column;
    }
    .chapterDetail_body{
        width: 100%;
        position: absolute;
        bottom: 0px;
        top: 0px;
        background-color: #f8f8f8;
        overflow: hidden;
        overflow-y: auto;
        .chapterDetail_title_card{
            margin: 17px 14px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141414;


        }
        .chapterDetail_card{
            display: flex;
            flex-direction: column;
            margin: 0 14px 10px 14px;
            background: white;
            height: 55px;
            justify-content: center;
            
            .answer_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .answer_continer{
                    width: 24px;
                    height: 24px;
                    border: 1px solid #363636;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .answer_name{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    display: flex;
                    align-items: center;
                }
                .row_width{
                    width: calc(100% - 48px);
                }
            }
            

        }

    }
    .min{
      font-size: 12px !important;
    }
    .max{
      font-size: 18px !important;
    }
}

</style>
